<script>
// Import Vue framework for building the application
import Vue from "vue";
import Utils from "@/helpers/Utils";

// Import layout and components for the page
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

// Import HTTP request handling module
import HttpRequest from "@/http/HttpRequest";
import Preference from "@/data/Preference";
import Role from "@/data/Roles";

// Initialize instances of Role, Preference, and HttpRequest
let roleChecker = new Role();
let preference = new Preference();
let httpRequest = new HttpRequest();


export default {
    data() {
        // Default values for data properties
        return {
            selectedradio: "Active", // Default selected radio button
            title: "Member Management", // Page titlefilteredRows
            access_property: "Project", // Access property
            role: preference.getData(Preference.KEY_ROLE_ID), // User role fetched from preference
            items: [
                // Navigation items
                {
                    text: "WORKANY",
                    to: "/",
                },
                {
                    text: "Projects",
                    active: true,
                },
            ],
            currentRoleList: [], // List of current roles
            projectList: [], // List of projects
            StaticMembersList: [], // Static list of members
            AllMembersList: [], // List of all members
            MembersFields: [
                // Fields for member data
                { key: "image", sortable: false, label: "User" },
                { key: "event_type", sortable: false, label: "Type" },
                { key: "event_date", sortable: false, label: "Date" },
                { key: "start_time", sortable: false, label: "Duration" },
                // { key: "end_time", sortable: false, label: "End" },
                // { key: "total_time", sortable: false, label: "Total" },
                { key: "event_note", sortable: false, label: "Note" },
                { key: "approve_status", sortable: false, label: "Status" },
                { key: "action", sortable: false, label: "Action" },
            ],
            ProjectsTasksData: [], // Data for projects and tasks
            myProjectRole: "9", // User's project role
            organizationList: [], // List of organizations
            totalRowsProjects: 1, // Total rows for projects
            memberPerPage: 10, // Members per page
            memberType: "Pending", // Member type
            memberTypeOptions: ["Pending", "Approval History"], // request type options
            departments: "All Departments", // Selected department
            departmentsOptions: ["All Departments"], // Department options
            selectedDepartmentForInvite: "", // Selected department for invitation
            selectedDepartmentName: "", // Selected department name
            selectedDepartmentRole: "", // Selected department role
            selectedDepartmentRoleID: "", // Selected department role ID
            selectedMemberEmail: "", // Selected member email
            selectedMemberName: null, // Selected member name
            selectedDepartmentNameID: "", // Selected department name ID
            selectedProjectForInvite: "", // Selected project for invitation
            selectedDepartmentRoleForInvite: "", // Selected department role for invitation
            selectedProjectRoleForInvite: "", // Selected project role for invitation
            otherRoleValue: "", // Selected project other role for invitation
            departmentsList: [], // List of departments
            projectsList: [], // List of projects
            memberPageOptions: [5, 10, 25, 50, 100], // Member page options
            memberCurrentPage: 1, // Current member page
            filterAllMembers: null, // Filter for all members
            filterOnProjectMembers: [], // Filter for project members
            selected: {}, // Selected data
            roleMap: {}, // Map of roles
            departmentRoleMap: [], // Map of department roles
            memberUserEmail: "", // Member user email
            projectName: "", // Project name
            roleObjectList: [], // List of role objects
            departmentRoleList: {}, // List of department roles
            loadingMembers: false, // Loading indicator for members
            isLoading: false, // Loading indicator for general
            filteredMembers: [],
        };
    },

    computed: {
        MembersRows() {
            return this.AllMembersList.length;
        },
    },

    watch: {},

    mounted() { },
    components: {
        Layout,
        PageHeader,
    },
    name: "projects",
    methods: {
        redirectToUrl(url) {
            if (url) {
                window.open(url, "_blank");
            }
        },
        onFilteredProjectMembers(filteredItems) {
            // Access the length of filtered rows
            const numberOfFilteredRows = filteredItems.length;
            this.filteredMembers = filteredItems;
            console.log(`Number of filtered rows: ${numberOfFilteredRows}`);
        },

        onModalHidden() {
            // Clean up tasks when the modal is hidden or closed
            this.ProjectsTasksData = [];
        },

        // Method to display projects and tasks for a selected member
        showProjectsTasks(item) {
            // Store the reference to the Vue component instance
            let _this = this;

            // Extract the email from the selected member item
            this.selectedMemberEmail = item.email;

            // loading indicator should be true until http request get success
            _this.isLoading = true;
            // Make an HTTP request to get projects and tasks for the selected member
            httpRequest
                .getProjectsTasks(this.selectedMemberEmail, true)
                .then(function (response) {
                    // loading indicator should be false when http request get success
                    _this.isLoading = false;

                    // Check if the request was successful
                    if (response.success) {
                        // Clear existing data in ProjectsTasksData array
                        _this.ProjectsTasksData = [];

                        // Assign the received data to the organizationList
                        _this.organizationList = response.data;

                        // Iterate through the organizationList and push data to ProjectsTasksData
                        _this.organizationList.forEach(function (orgData) {
                            _this.ProjectsTasksData.push(orgData);
                        });
                    }
                });
        },

        // Method to set member details for editing
        memberEditItem(item) {
            // Set selectedDepartmentName to the department_name of the selected item
            this.selectedDepartmentName = item.department_name;

            // Set selectedDepartmentRole to the corresponding role from roleMap
            this.selectedDepartmentRole = this.roleMap.get(
                item.department_role.toString()
            );

            // Set selectedDepartmentRoleID to the department_role of the selected item
            this.selectedDepartmentRoleID = item.department_role;

            // Set selectedMemberName to the user_name of the selected item
            this.selectedMemberName = item.user_name;

            // Set selectedMemberEmail to the email of the selected item
            this.selectedMemberEmail = item.email;
        },

        // Method to fetch the list of projects
        getProjectList() {
            // Store the reference to the Vue component instance
            let _this = this;

            // Determine the access permission based on the user's role and access property
            let accessPermission = this.isRoleAccess(
                this.role,
                this.access_property,
                "read"
            );

            // Make an HTTP request to get the list of projects
            return httpRequest
                .getProjectList(accessPermission)
                .then(function (response) {
                    // Check if the request was successful
                    if (response.success) {
                        // Assign the received data to the projectList
                        _this.projectList = response.data;

                        // Iterate through the projectList and add projects to projectsList if project_status is true
                        _this.projectList.forEach(function (projectData) {
                            if (projectData.project.project_status) {
                                _this.projectsList.push(projectData.project);
                            }
                        });
                    }
                    // Return nothing explicitly, as the data is already assigned to projectList and projectsList
                    return;
                });
        },

        // Method to generate a formatted string for projects in a popover
        popoverMethod(item) {
            // Map project names to HTML strong tags with a red background
            const projectsString = item.project_names
                .map(
                    (projectName) =>
                        "<strong >" +
                        (projectName != null ? projectName : "") +
                        "</strong> "
                )
                .join("<br>");

            // Return the formatted string
            return projectsString;
        },

        // Method to filter members based on selected departments and member type
        filterMembers() {
            // Check if all departments are selected
            if (this.departments === "All Departments") {
                if (this.memberType == 'Pending') {
                    // Filter members based on member type
                    this.AllMembersList = this.StaticMembersList.filter(
                        (member) => member.approve_status === this.memberType.toLowerCase()
                    );
                } else {
                    this.AllMembersList = this.StaticMembersList.filter(
                        (member) => member.approve_status !== 'pending'
                    );

                }

            } else {


                if (this.memberType == 'Pending') {
                    // Filter members based on member type
                    this.AllMembersList = this.StaticMembersList.filter(
                        (member) => member.approve_status === this.memberType.toLowerCase() && member.name === this.departments
                    );
                } else {
                    this.AllMembersList = this.StaticMembersList.filter(
                        (member) => member.approve_status !== 'pending' && member.name === this.departments
                    );

                }
                // // Filter members based on both member type and selected department
                // this.AllMembersList = this.StaticMembersList.filter(
                //     (member) =>

                //         member.name === this.departments
                // );
            }

        },

        // Method to fetch the list of organizations
        getOrganizationList() {
            // Store the reference to the Vue component instance
            let _this = this;

            // Make an HTTP request to get the list of organizations
            httpRequest.getOrganizationList(true).then(function (response) {
                // Check if the request was successful
                if (response.success) {
                    // Assign the received data to the organizationList
                    _this.organizationList = response.data;

                    // Iterate through the organizationList and populate departmentsOptions and departmentsList
                    _this.organizationList.forEach(function (orgData) {
                        _this.departmentsOptions.push(orgData.name);
                        _this.departmentsList.push(orgData);
                    });
                }
            });
        },

        // Method to deactivate and remove a member from the organization
        rejectRequest(userData) {
            // Store the reference to the Vue component instance
            let _this = this;

            // Determine the access permission based on the user's role and organization role
            let accessPermission = this.doubleRoleCheck(
                this.role,
                this.myOrgRole,
                "Member",
                "update"
            );

            let whatIsRequest = 'rejected'



            // Display a confirmation modal using sweetalert
            _this
                .$swal({
                    text: "Are you sure you want to reject this request?",
                    showCancelButton: true,
                    confirmButtonText: "YES",
                    cancelButtonText: "CANCEL",
                    confirmButtonColor: " rgb(255, 124, 71)",
                })
                .then((result) => {
                    // Check if the user confirmed the action
                    if (result.value) {
                        // Log user data to the console for debugging
                        //console.log(userData);

                        // Make an HTTP request to delete the member from the organization
                        // whatIsRequestType, event_id, request_user_id , jira_issue_key, meeting_time, event_note, accessPermission
                        httpRequest
                            .approveThisUserEvent(

                                whatIsRequest,
                                userData.event_id,
                                userData.id,
                                userData.jira_issue_key,
                                this.convertToSeconds(userData.total_time),
                                userData.event_note,
                                accessPermission
                            )
                            .then(function (response) {
                                // Check if the request was successful
                                if (response.success) {
                                    // Update the list of members after removal
                                    _this.getMembersDetails();
                                } else {
                                    // Display an error message using sweetalert
                                    _this.$swal({
                                        text: "You can't reject this request!",
                                    });
                                }
                            });
                    }
                });
        },
        convertToSeconds(timeString) {
            // Regular expression to extract hours and minutes
            const timeRegex = /(\d+)\s*hours?\s*(\d+)\s*minutes?/i;

            // Match the input string with the regular expression
            const matches = timeString.match(timeRegex);

            if (matches) {
                // Extract hours and minutes from the matched groups
                const hours = parseInt(matches[1], 10);
                const minutes = parseInt(matches[2], 10);

                // Convert hours and minutes to seconds
                const totalSeconds = (hours * 3600) + (minutes * 60);
                return totalSeconds;
            } else {
                throw new Error("Invalid time format");
            }
        },

        approveRequest(userData) {
            // Store the reference to the Vue component instance
            let _this = this;

            // Determine the access permission based on the user's role and organization role
            let accessPermission = this.doubleRoleCheck(
                this.role,
                this.myOrgRole,
                "Member",
                "update"
            );





            let whatIsRequest = 'approved'



            // Display a confirmation modal using sweetalert
            _this
                .$swal({
                    text: "Are you sure you want to approve this request?",
                    showCancelButton: true,
                    confirmButtonText: "YES",
                    cancelButtonText: "CANCEL",
                })
                .then((result) => {
                    // Check if the user confirmed the action
                    if (result.value) {
                        // Log user data to the console for debugging
                        //console.log(userData);
                        // Make an HTTP request to delete the member from the organization
                        //(whatIsRequestType, event_id, request_user_id , jira_issue_key, meeting_time, event_note, accessPermission
                        httpRequest
                            .approveThisUserEvent(
                                whatIsRequest,
                                userData.event_id,
                                userData.id,
                                userData.jira_issue_key,
                                this.convertToSeconds(userData.total_time),
                                userData.event_note,
                                accessPermission
                            )
                            .then(function (response) {
                                // Check if the request was successful
                                if (response.success) {
                                    // Update the list of members after removal
                                    _this.getMembersDetails();
                                } else {
                                    // Display an error message using sweetalert
                                    _this.$swal({
                                        text: "You can't approve this request!",
                                    });
                                }
                            });
                    }
                });
        },
        refactorRequest(userData) {
            // Store the reference to the Vue component instance
            let _this = this;

            // Determine the access permission based on the user's role and organization role
            let accessPermission = this.doubleRoleCheck(
                this.role,
                this.myOrgRole,
                "Member",
                "update"
            );

            let whatIsRequest = 'pending'



            // Display a confirmation modal using sweetalert
            _this
                .$swal({
                    text: "Are you sure you want to refactor this request?",
                    showCancelButton: true,
                    confirmButtonText: "YES",
                    cancelButtonText: "CANCEL",
                })
                .then((result) => {
                    // Check if the user confirmed the action
                    if (result.value) {
                        // Log user data to the console for debugging
                        //console.log(userData);
                        // Make an HTTP request to delete the member from the organization
                        httpRequest
                            .approveThisUserEvent(
                                whatIsRequest,
                                userData.event_id,
                                userData.id,
                                userData.jira_issue_key,
                                this.convertToSeconds(userData.total_time),
                                userData.event_note,
                                accessPermission
                            )
                            .then(function (response) {
                                // Check if the request was successful
                                if (response.success) {
                                    _this.memberType = 'Pending'
                                    // Update the list of members after removal
                                    _this.getMembersDetails();
                                } else {
                                    // Display an error message using sweetalert
                                    _this.$swal({
                                        text: "You can't refactor this request!",
                                    });
                                }
                            });
                    }
                });
        },

        // Method to set the CSS class for a row based on item status
        rowClass(item, type) {
            // Check if the item is null or the type is not "row"
            if (!item || type !== "row") return;

            // Return "secondary" class if the item status is "Deactive"
            if (item.status == "Deactive") return "secondary";
        },

        // Method to check if the user role has access to a specific resource and action
        isRoleAccess(role_id, resource, action) {
            // Delegate the access check to a role checker utility
            return roleChecker.checkAccess(role_id, resource, action);
        },

        // Method to check access based on two roles for a given access property and action
        doubleRoleCheck(myRole, myRoleOrg, access_property, action) {
            // Check if either of the roles has access to the specified property and action
            if (
                this.isRoleAccess(myRole, access_property, action) ||
                this.isRoleAccess(myRoleOrg, access_property, action)
            ) {
                return true; // Return true if either role has access
            } else {
                return false; // Return false if neither role has access
            }
        },

        // Method to fetch details of members
        getMembersDetails() {
            // Store the reference to the Vue component instance
            let _this = this;

            // Set loadingMembers to true to indicate that the data is being fetched
            _this.loadingMembers = true;

            let timezone = Utils.getMyTimezone();


            // Make an HTTP request to get details of members
            httpRequest.getMembersRequestsDetails(timezone).then(function (response) {
                // Set loadingMembers to false as the data fetching is completed
                _this.loadingMembers = false;

                // Check if the request was successful
                if (response.success) {
                    // Assign the received data to orgMembersList
                    _this.orgMembersList = response.data;

                    // Clear and populate AllMembersList with the retrieved members
                    _this.AllMembersList = [];
                    _this.orgMembersList.forEach(function (member) {
                        _this.AllMembersList.push(member);
                    });

                    // Clone AllMembersList into StaticMembersList for filtering
                    _this.StaticMembersList = _this.AllMembersList;

                    // Filter Active members and update AllMembersList
                    _this.AllMembersList = _this.StaticMembersList.filter(
                        (member) => member.approve_status === "pending"
                    );
                    console.log(_this.AllMembersList);
                } else {
                    // If the request was not successful, clear AllMembersList
                    _this.AllMembersList = [];
                }
            });
        },

        // Method to fetch the list of roles based on the role_type
        getRoleList(role_type) {
            // Store the reference to the Vue component instance
            let _this = this;

            // Make an HTTP request to get the list of roles based on the role_type
            httpRequest.getRole(role_type).then(function (response) {
                // Check if the request was successful
                if (response.success) {
                    // Assign the received data to currentRoleList
                    _this.currentRoleList = response.data;

                    // Check if the role_type is "org"
                    if (role_type == "org") {
                        // Populate departmentRoleMap with role data
                        _this.currentRoleList.forEach(function (roleData) {
                            _this.departmentRoleMap.push({
                                id: roleData.role_id,
                                title: roleData.title,
                            });
                        });
                    } else {
                        // Populate roleObjectList with role data excluding "Other"
                        _this.currentRoleList.forEach(function (roleData) {
                            if (roleData.title !== "Other") {
                                _this.roleObjectList.push({
                                    id: roleData.role_id,
                                    title: roleData.title,
                                });
                            }
                        });

                        // Add "Other" role to roleObjectList with a specific id
                        _this.roleObjectList.push({ id: 13, title: "Other" });
                    }
                }
            });
        },

        // Method to fetch all roles and populate a Map with role_id as key and title as value
        getAllRoleList() {
            // Store the reference to the Vue component instance
            let _this = this;

            // Initialize a new Map for storing roles
            _this.roleMap = new Map();

            // Make an HTTP request to get all roles
            httpRequest.getAllRole().then(function (response) {
                // Check if the request was successful
                if (response.success) {
                    // Assign the received data to currentRoleList
                    _this.currentRoleList = response.data;

                    // Iterate through each role data and populate roleMap
                    _this.currentRoleList.forEach(function (roleData) {
                        _this.roleMap.set(roleData.role_id, roleData.title);
                    });
                }
            });
        },

        // Method to invite a new member to the organization and project
        inviteMember() {
            // Store the reference to the Vue component instance
            let _this = this;

            // Set loadingMembers to true to indicate that the data is being processed
            _this.loadingMembers = true;

            // Determine the access permissions based on roles for member invitation
            let accessPermission = _this.doubleRoleCheck(
                _this.role,
                _this.myOrgRole,
                "Member",
                "write"
            );

            // Reference to the swal function for displaying alerts
            let swal = this.$swal;

            // Placeholder for roleMap (appears unused in the provided code)
            _this.roleMap;

            // Make an HTTP request to invite a member to the organization and project
            return httpRequest
                .inviteMemberToDepartment(
                    _this.selectedDepartmentForInvite, // Selected department id
                    _this.selectedDepartmentRoleForInvite, // Selected role id of the selected department
                    _this.selectedProjectForInvite, // Selected project id
                    _this.selectedProjectRoleForInvite, // Selected project role id
                    _this.otherRoleValue, // Selected project other role in string
                    _this.memberUserEmail, // Member's email
                    _this.memberUserName, // Member's name
                    accessPermission // Access permission based on roles
                )
                .then(function (response) {
                    // Set loadingMembers to false as the data processing is completed
                    _this.loadingMembers = false;

                    // Check if the invitation was successful
                    if (response.success) {
                        // Update the list of members with the new member
                        _this.getMembersDetails();

                        // Hide the modal used for member invitation
                        _this.$bvModal.hide("modal-invite-member");

                        // Display a success message using swal
                        swal("Member adding mail has been sent.");

                        // Clear the input fields for member name and email
                        _this.memberUserName = "";
                        _this.memberUserEmail = "";
                    } else {
                        // Display an error message using swal if the invitation fails
                        swal(response.message);
                    }
                    return;
                });
        },

        // Method to edit the information of an existing member
        editMemberInfo() {
            // Find the organization_id based on the selected department name
            let selectedDepartmentNameID = (
                this.departmentsList.find(
                    (department) => department.name === this.selectedDepartmentName
                ) || {}
            ).organization_id;

            // Store the reference to the Vue component instance
            let _this = this;

            // Check the access permission for updating a member
            let accessPermission = _this.doubleRoleCheck(
                _this.role,
                _this.myOrgRole,
                "Member",
                "update"
            );

            // Set loadingMembers to true to indicate the loading state
            _this.loadingMembers = true;

            // Store the reference to the swal function for displaying alerts
            let swal = this.$swal;

            // Make an HTTP request to update the information of an organization member
            return httpRequest
                .updateOrganizationMember(
                    selectedDepartmentNameID,
                    _this.selectedMemberEmail,
                    _this.selectedMemberName,
                    _this.selectedDepartmentRoleID,
                    accessPermission
                )
                .then(function (response) {
                    // Set loadingMembers to false as the request is completed
                    _this.loadingMembers = false;

                    if (response.success) {
                        // Update the member details after successful edit
                        _this.getMembersDetails();

                        // Hide the modal-edit-member-info
                        _this.$bvModal.hide("modal-edit-member-info");

                        // Show a success message to the user
                        swal(response.message);
                    } else {
                        // Show an error message to the user if the edit fails
                        swal(response.message);
                    }

                    // Return control to the calling context
                    return;
                });
        },
    },
    // The created lifecycle hook is called when the Vue instance is created
    async created() {
        // Call the method to fetch and set all available roles
        await this.getAllRoleList();

        // Use Vue.set to dynamically add roles to the departmentRoleList
        Vue.set(this.departmentRoleList, 5, "Member");
        Vue.set(this.departmentRoleList, 4, "Owner");

        // Call the method to fetch and set roles for projects
        await this.getRoleList("project");

        // Call the method to fetch and set roles for organizations
        await this.getRoleList("org");

        // Call the method to fetch member details
        await this.getMembersDetails();

        // Call the method to fetch the list of organizations
        await this.getOrganizationList();

        // Call the method to fetch the list of projects
        await this.getProjectList();
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <!--project member block-->
            <div class="col-xl-12 mb-4">
                <div class="card h-100">
                    <div class="card-body">
                        <!-- page sub-header -->
                        <h4 class="card-title mb-4">Requests</h4>
                        <div>
                            <!-- Member table filter options -->
                            <div>
                                <div id="members-table_length"
                                    class="dataTables_length d-flex justify-content-between mb-3">
                                    <div class="d-inline-flex align-items-center">
                                        <!-- member display-Number  row -->
                                        <div>
                                            <label class="d-inline-flex align-items-center">
                                                Display
                                                <b-form-select v-model="memberPerPage" size="sm"
                                                    :options="memberPageOptions"></b-form-select>
                                            </label>
                                        </div>
                                        <!-- member type row  -->
                                        <div class="ml-3">
                                            <label class="d-inline-flex align-items-center">
                                                <span style="white-space: nowrap;">Request type</span>

                                                <b-form-select v-model="memberType" size="sm"
                                                    :options="memberTypeOptions"
                                                    @change="filterMembers"></b-form-select>
                                            </label>
                                        </div>

                                        <!-- Department row  -->
                                        <div class="ml-3">
                                            <label class="d-inline-flex align-items-center">
                                                Department
                                                <b-form-select v-model="departments" size="sm"
                                                    :options="departmentsOptions"
                                                    @change="filterMembers"></b-form-select>
                                            </label>
                                        </div>

                                        <!-- Search row-->
                                        <div>
                                            <div id="members-table_filter" class="dataTables_filter text-md-right">
                                                <label class="d-inline-flex align-items-center">
                                                    Search
                                                    <b-form-input v-model="filterAllMembers" type="search"
                                                        class="form-control form-control-sm ml-2"></b-form-input>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <!-- All Members table -->
                            <b-table show-empty class="mb-0" :striped="false" :bordered="true" :hover="false"
                                :dark="false" :items="AllMembersList" :fields="MembersFields" :busy="loadingMembers"
                                responsive="" :per-page="memberPerPage" :current-page="memberCurrentPage"
                                :filter="filterAllMembers" :filter-included-fields="filterOnProjectMembers"
                                @filtered="onFilteredProjectMembers" :tbody-tr-class="rowClass">
                                <template v-for="field in MembersFields" v-slot:[`head(${field.key})`]="{ label }">
                                    <span class="" :style="{ color: '#ff7c47' }" v-bind:key="field.key">{{ label
                                        }}</span>
                                </template>
                                <template v-slot:cell(image)="data">
                                    <div class="d-flex align-items-center">
                                        <!-- Avatar -->
                                        <b-avatar v-b-tooltip.hover.html="tipMethod" :src="data.item.image"></b-avatar>
                                        <!-- User Info -->
                                        <div class="ml-3">
                                            <div class="text-dark">{{ data.item.user_name }}
                                            </div>
                                            <div>
                                                <a class="text-secondary" :href="'mailto:' + data.item.email">{{
            data.item.email }}</a>
                                                <div class="text-secondary">{{ data.item.name }}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </template>


                                <template v-slot:cell(start_time)="data">
                                    <div class="d-flex align-items-center">
                                        <!-- User Info -->
                                        <div class="ml-3">
                                            <div>
                                                <div class="text-secondary">
                                                    <span>{{ data.item.start_time }} - </span>
                                                    <span>{{ data.item.end_time }} </span>

                                                    <b-badge pill variant="secondary">
                                                        <span>{{ data.item.total_time.replace(' hours',
            'h').replace(' minutes', 'm') }}</span>
                                                    </b-badge>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </template>
                                <template v-slot:cell(approve_status)="row">
                                    <b-badge pill variant="success" v-if="row.item.approve_status == 'approved'">
                                        {{ row.item.approve_status }}
                                    </b-badge>
                                    <b-badge pill variant="danger" v-if="row.item.approve_status == 'rejected'">
                                        {{ row.item.approve_status }}
                                    </b-badge>
                                    <b-badge pill variant="info" v-if="row.item.approve_status == 'pending'">
                                        {{ row.item.approve_status }}
                                    </b-badge>
                                    <!-- <b-badge pill variant="secondary" v-else-if="row.item.role_title == 'User'">
                                        {{ row.item.role_title }}
                                    </b-badge>
                                    <b-badge pill variant="success" v-else-if="row.item.role_title == 'Super Admin'">
                                        {{ row.item.role_title }}
                                    </b-badge>
                                    <b-badge pill variant="primary" v-else>
                                        {{ row.item.role_title }}
                                    </b-badge>
                                    <b-badge class="" pill variant="success" v-if="row.item.department_role == 4">
                                        Department
                                        {{ roleMap.get(row.item.department_role.toString()) }}
                                    </b-badge> -->
                                </template>



                                <template v-slot:cell(action)="row">
                                    <b-button v-if="doubleRoleCheck(
            role,
            myProjectRole,
            'Member',
            'update'
        ) && memberType == 'Pending'
            " variant="outline-info" size="sm" v-b-tooltip.hover title="Approve" v-on:click="approveRequest(row.item)">
                                        <i class="ri-check-line"></i>
                                    </b-button>
                                    <b-button v-if="(doubleRoleCheck(
            role,
            myProjectRole,
            'Member',
            'delete'
        ) &&
            memberType == 'Pending')
            " variant="outline-danger" v-b-tooltip.hover title="Reject" size="sm" class="ml-2"
                                        v-on:click="rejectRequest(row.item)">
                                        <i class="ri-close-line"></i>
                                    </b-button>
                                    <b-button disabled v-if="isRoleAccess(role, 'Member', 'delete') &&
            memberType == 'Approval History'
            " @click="refactorRequest(row.item)" style="margin-left: 15px" variant="outline-secondary" size="sm">
                                        <i class="ri-arrow-go-back-line"></i>
                                    </b-button>
                                </template>

                            </b-table>

                            <!-- Group of all modals in this page -->
                            <div class="align-items-center text-center text-md-left">
                                <!-- Modal for edit member's role, department etc-->
                                <b-modal id="modal-edit-member-info" centered title="Edit member" hide-footer>
                                    <form class="form-horizontal" @submit.prevent="">
                                        <div class="form-group auth-form-group-custom mb-2">
                                            <i class="ri-user-line auti-custom-input-icon"></i>
                                            <label for="username">Name</label>
                                            <input v-model="selectedMemberName" type="text" class="form-control"
                                                id="username" placeholder="Enter name" />
                                        </div>

                                        <div class="form-group auth-form-group-custom mb-2">
                                            <i class="ri-mail-line auti-custom-input-icon"></i>
                                            <label for="useremail">Email</label>
                                            <input v-model="selectedMemberEmail" type="email" class="form-control"
                                                id="useremail" placeholder="Enter email" readonly />
                                        </div>
                                        <div class="form-group auth-form-group-custom mb-2">
                                            <i class="ri-building-2-line auti-custom-input-icon"></i>
                                            <label for="role">Department</label>
                                            <select class="form-control custom-select" id="department"
                                                v-model="selectedDepartmentName">
                                                <option v-for="(value, key) in departmentsList" :key="key"
                                                    :value="value.name">
                                                    {{ value.name }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="form-group auth-form-group-custom mb-2">
                                            <i class="ri-medal-line auti-custom-input-icon"></i>
                                            <label for="role">Department Role</label>
                                            <select class="form-control custom-select" id="department"
                                                v-model="selectedDepartmentRoleID">
                                                <option v-for="(value, key) in departmentRoleMap" :key="key"
                                                    :value="value.id">
                                                    {{ value.title }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="mt-4 text-center">
                                            <b-button variant="primary" class="customs-bg-color" size="lg" type="submit"
                                                v-on:click="editMemberInfo">SAVE
                                                CHANGE
                                                <i class="ri-arrow-right-s-line" style="vertical-align: bottom"></i>
                                            </b-button>
                                        </div>
                                    </form>
                                </b-modal>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<style>
.customs-bg-color {
    background-color: #ff7c47 !important;
    border: none;
}

.txt-custom-color {
    color: #ff7c47 !important;
}

.rowClass {
    vertical-align: middle;
}

.selected-row {
    background-color: #34c38f;
}

.popover-header {
    background-color: rgb(232, 244, 248);
}

.popover-body {
    background-color: #eff2f7;
}
</style>
<style scoped>
.custom-modal {
    max-height: 250px !important;
    /* Set your desired height here */
    overflow-y: auto !important;
    /* Add scroll if content exceeds the max height */
}

.no-data-message {
    text-align: center;
    padding: 20px;
    background-color: #f8d7da;
    /* Light red background color */
    border: 1px solid #dc3545;
    /* Red border */
    color: #721c24;
    /* Dark red text color */
    border-radius: 5px;
    /* Rounded corners */
}

.no-data-message p {
    margin: 0;
}

.custom-modal h6 {
    color: #007bff;
    /* Blue color for project names */
    margin-bottom: 10px;
}

.custom-modal ul {
    list-style-type: none;
    padding: 0;
}

.custom-modal li {
    margin-bottom: 5px;
}

.custom-modal a {
    color: #007bff;
    /* Blue color for task links */
    text-decoration: none;
}

.custom-modal a:hover {
    text-decoration: underline;
}

.avatar-green {
    border: 3px solid #1cbb8c;
}

.avatar-gray {
    border: 2px solid gray;
}

.avatar-pink {
    border: 2px solid blue;
}
</style>

<style>
.truncate {
    white-space: nowrap;
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cursor-pointer-custom {
    cursor: pointer;
}

.swal2-styled.swal2-confirm {
    background-color: rgb(255, 124, 71) !important;
}
</style>
